.absolute-zoom-cropper-slider {
	width: 100%;
	height: 20px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	border-radius: 5px;
	cursor: pointer;
}

.absolute-zoom-cropper-slider__wrapper {
	display: flex;
	align-items: center;
	max-width: 400px;
	width: 100%;
}

.absolute-zoom-cropper-slider__line {
	background: rgba(white, 0.4);
	height: 2px;
	width: 100%;
	border-radius: 5px;
	display: flex;
	position: relative;
	align-items: center;
}

.absolute-zoom-cropper-slider__fill {
	background: #61dafb;
	align-self: stretch;
	flex-basis: auto;
	flex-direction: column;
	flex-shrink: 0;
}

.absolute-zoom-cropper-slider__circle {
	width: 30px;
	height: 30px;
	margin-left: -15px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	transition-duration: 0.2s;
	transition-property: background-color, box-shadow;
	background-color: transparent;
}

.absolute-zoom-cropper-slider__circle:hover {
	background-color: rgba(97, 218, 251, 0.1);
}

.absolute-zoom-cropper-slider__circle--focus {
	background-color: rgba(97, 218, 251, 0.2);
}

.absolute-zoom-cropper-slider__inner-circle {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: #61dafb;
	transform: scale(1);
	transition-duration: 0.1s;
	transition-property: transform;
	box-shadow: rgba(97, 218, 251, 0.2) 0px 0px 7px,
		rgba(97, 218, 251, 0.15) 0px 1px 3px 1px;
}

.absolute-zoom-cropper-slider__inner-circle--focus {
	transform: scale(1.2);
}
