.custom-wrapper {
	flex-grow: 1;
	min-height: 0;
}

.custom-wrapper__navigation {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
}

.custom-wrapper__actionButtons {
	position: absolute;
	top: 4px;
	right: 4px;
	z-index: 2;
	display: flex;
	flex-direction: row;
	gap: 4;
}

.custom-wrapper__actionButton {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 100px;
	padding: 8px;
}

.custom-wrapper__actionButton:hover {
	background-color: rgba(0, 0, 0, 0.6);
}
