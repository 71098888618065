.custom-cropper {
	height: 320px;
	width: 600px;
	margin-top: 8px;
	margin-left: auto;
	margin-right: auto;
}
@media screen and (max-width: 782px) {
  .custom-cropper {
    width: 100%;
  }
}

